import {ENV} from './app'
export default {
    socialAuth : {
        providers : {
            linkedin : {
                clientId : ENV.VUE_APP_LINKEDIN_CLIENT_ID,
                redirectUri : ENV.VUE_APP_LINKEDIN_REDIRECT_URL,
                scope : ['r_emailaddress,r_liteprofile'],
            },
            google : {
                clientId : ENV.VUE_APP_GOOGLE_CLIENT_ID,
                redirectUri : ENV.VUE_APP_GOOGLE_REDIRECT_URL,
            }
        }
    }
}